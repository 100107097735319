import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterOutlet } from '@angular/router';
import { FooterComponent } from './domains/navigation/footer/footer.component';
import { HeaderComponent } from './domains/navigation/header/header.component';
import { CtBatchStoreService } from './services/app-state/ct-batch-store.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { BuildSimpleExtractionResultDto } from './build-simple/build-simple-model';
import { CtBatchApiService } from './services/api/ct-batch-api.service';
import { BuildSimpleExtractedDataProcessingService } from './build-simple/build-simple-extracted-data-processing.service';
import { PageImageService } from './services/api/page-image.service';
import { WorkflowService } from './services/app-state/workflow.service';
import { OcrService } from './services/ocr/ocr.service';
import { AuthenticationService } from './services/authentication.service';
import { ConfigService } from './services/config/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { CompletionDialogComponent } from './domains/dialogs/completion-dialog/completion-dialog.component';
import { AppView } from './models/interfaces';
import { ExtractedDataStoreService } from './services/extracted-data/extracted-data-store.service';
import { CtBatchLock } from './models/ct-batch-model';

@UntilDestroy()
@Component({
  selector: 'app-batch',
  standalone: true,
  imports: [FooterComponent, HeaderComponent, RouterOutlet, CompletionDialogComponent],
  templateUrl: './batch.component.html',
})
export class BatchComponent implements OnInit {
  private route = inject(ActivatedRoute);
  protected ctBatchStoreService = inject(CtBatchStoreService);
  private authenticationService = inject(AuthenticationService);
  private confirmService = inject(ConfirmService);
  private ctBatchApiService = inject(CtBatchApiService);
  private buildSimpleExtractedDataProcessingService = inject(
    BuildSimpleExtractedDataProcessingService,
  );
  private pageImageService = inject(PageImageService);
  protected workflowService = inject(WorkflowService);
  private ocrService = inject(OcrService);
  private configService = inject(ConfigService);
  protected confirmationService = inject(ConfirmationService);
  protected extractedDataStoreService = inject(ExtractedDataStoreService);

  protected usersFullName = '<loading user>';
  protected doneLoading = false;

  // TODO: extract this in some monitoring service
  protected completeOcrRequestsCount = 0;
  protected completeImageRequestsCount = 0;

  @ViewChild('completionDialog', { static: true }) completionDialog!: Confirmation;

  ngOnInit(): void {
    this.authenticationService
      .loadUserProfile()
      .then((userProfile) => {
        this.usersFullName = userProfile.username ?? '<unknown user>';

        this.route.paramMap.subscribe((params) => {
          const retrievedCtBatchId = this.getRouteBatchIdParameter(params);
          const savedCtBatchId = this.ctBatchStoreService.ctBatchId;

          this.ctBatchStoreService.ctBatchId = retrievedCtBatchId;

          const subscription = this.loadCtBatch(
            retrievedCtBatchId,
            savedCtBatchId !== retrievedCtBatchId &&
              !this.configService.config.ignoreBuildSimpleExtractionResults,
          );
          this.confirmService.spinner({ subscription });
        });
      })
      .catch(console.error);

    this.workflowService.openCompletionDialog$.pipe(untilDestroyed(this)).subscribe((bool) => {
      if (bool) {
        this.completionDialog.key = `completionDialog`;
        this.confirmationService.confirm(this.completionDialog);
      }
    });
  }

  private getRouteBatchIdParameter(params: ParamMap) {
    const ctBatchId = params.get('id');

    if (!ctBatchId) {
      throw new Error('Invalid path - expected /batch/{batch-id}');
    }

    return ctBatchId;
  }

  loadCtBatch(ctBatchId: string, shouldUtilizeExtractionData: boolean) {
    return this.ctBatchApiService
      .getCtBatch(ctBatchId)
      .pipe(
        switchMap((batch) =>
          this.ctBatchApiService
            .loadExtractionDataByDocumentId(batch)
            .pipe(map((extractionDataByDocumentId) => ({ batch, extractionDataByDocumentId }))),
        ),
      )
      .subscribe({
        next: (result) => {
          if (shouldUtilizeExtractionData) {
            this.buildSimpleExtractedDataProcessingService.storeExtractedSuggestions(
              result.batch,
              result.extractionDataByDocumentId as Record<string, BuildSimpleExtractionResultDto>,
            );
          }

          const sortedDocumentList = result.batch.documents.toSorted(
            (document1, document2) => document1.index - document2.index,
          );
          const batch = { ...result.batch };
          batch.documents = sortedDocumentList;
          this.ctBatchStoreService.ctBatch = batch;
          this.pageImageService.init(
            result.batch.documents.flatMap((document) => document.pages.map((page) => page.id)),
          );

          this.handleItemStatus();
        },
        error: (error) => {
          console.error(error);
          this.confirmService.snackError(`Vorgang ${ctBatchId} konnte nicht geladen werden`);
        },
      });
  }

  handleItemStatus() {
    const ctBatch = this.ctBatchStoreService.ctBatch;

    const subscription = this.ctBatchApiService
      .getCtBatchLock(ctBatch.id)
      .pipe(
        switchMap((lock) => {
          return lock ? this.verifyLockedBySameUser(lock) : this.lockCtBatch();
        }),
        switchMap(() => {
          this.workflowService.init();
          return this.ocrService
            .init()
            .pipe(tap((finishedCount) => (this.completeOcrRequestsCount = finishedCount)));
        }),
      )
      .subscribe({
        complete: () => (this.doneLoading = true),
        error: (error) => {
          console.log(error);
          this.confirmService.snackError(error);
        },
      });

    this.confirmService.spinner({ subscription });
  }

  lockCtBatch(): Observable<void> {
    return this.ctBatchApiService
      .lockCtBatch(this.ctBatchStoreService.ctBatch.id, this.authenticationService.getUserName())
      .pipe(
        tap({
          error: () => {
            throwError(
              () =>
                `Fehler beim Sperren des Vorgangs mit der ID ${this.ctBatchStoreService.ctBatch.id}.`,
            );
          },
        }),
      );
  }

  private verifyLockedBySameUser(lock: CtBatchLock): Observable<void> {
    const lastLockedUser = lock.username;
    const ctBatchId = this.ctBatchStoreService.ctBatch.id;

    if (this.usersFullName !== lastLockedUser) {
      return throwError(
        () => `Vorgang ${ctBatchId} ist bereits durch den Nutzer ${lastLockedUser} gesperrt`,
      );
    }

    return of(undefined);
  }

  completeProcess() {
    this.workflowService.navigateToAudit();
  }

  protected readonly AppView = AppView;
}
