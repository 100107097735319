import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { ApiService } from '@l21s-ecnps/gui-commons/api-service';
import { Observable } from 'rxjs';
import { OcrData } from '../../models/ct-batch-model';

@Injectable({
  providedIn: 'root',
})
export class PageDataApiService extends ApiService {
  constructor(configService: ConfigService) {
    super(() => configService.config.apiBaseUrl);
  }

  getOcrForPage(pageId: string): Observable<OcrData> {
    return this.get(`api/page/${pageId}/ocr`);
  }
}
