<div class="flex h-full flex-col gap-y-4 bg-secondary antialiased">
  @if (doneLoading) {
    <app-header [usersFullName]="usersFullName" />
    <div class="flex h-full min-h-0 flex-col gap-y-4 px-4">
      <div class="h-full min-h-0">
        <!-- Change component on step in extraction process -->
        <router-outlet />
      </div>
    </div>
    <app-footer />
  } @else {
    <div>loading...</div>
    @let ocrProgress = (completeOcrRequestsCount / ctBatchStoreService.ctBatchPageCount) * 100;
    <div
      class="relative w-[300px] border text-center"
      [style.background]="
        'linear-gradient(90deg, lightgray ' + ocrProgress + '%, white ' + ocrProgress + '%)'
      "
    >
      Ocr: {{ completeOcrRequestsCount }} / {{ ctBatchStoreService.ctBatchPageCount }}
    </div>
  }
</div>

<app-completion-dialog
  #completionDialog
  (acceptedDialog)="completeProcess()"
  (clickedFieldEdit)="workflowService.jumpToView(AppView.FIELD_EXTRACTION)"
  (clickedForderungenEdit)="workflowService.jumpToView(AppView.TABLE_EXTRACTION)"
  [extractedFieldsObject]="extractedDataStoreService.extractedFields"
  [auditPositionArray]="extractedDataStoreService.auditPositionArray"
/>
