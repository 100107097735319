import { inject, Injectable } from '@angular/core';
import { Vector2, WordRect } from '../../models/interfaces';
import { CtBatchStoreService } from '../app-state/ct-batch-store.service';
import { from, map, mergeMap, Observable, scan } from 'rxjs';
import { PageDataApiService } from '../api/page-data-api.service';
import { CtPage, OcrData } from '../../models/ct-batch-model';
import { MAX_CONCURRENT_REQUESTS } from '../api/page-image.service';

/**
 * This service provides helper functions and simple algorithms to
 * extract data from the ocr layer of a page.
 * Basically what we do is a lot of rect-rect intersection checking, where
 * all positions in this service are expected to be in [0, 1] representing
 * a width / height percentage (of a page).
 */
@Injectable({
  providedIn: 'root',
})
export class OcrService {
  private ctBatchStoreService = inject(CtBatchStoreService);
  private pageDataApiService = inject(PageDataApiService);

  private ocrDataByPageId: Record<
    string,
    {
      wordsByRect: Map<WordRect, string>;
    }
  > = {};

  /**
   * Loads ocr data and emits number of completed requests after each
   * completed requests. TODO: refactor this via some monitoring service?
   */
  init(): Observable<number> {
    const pages = this.ctBatchStoreService.ctBatch.documents.flatMap((document) => document.pages);

    return from(pages.map((page) => page.id)).pipe(
      mergeMap(
        (pageId) =>
          this.pageDataApiService.getOcrForPage(pageId).pipe(
            map((ocrData) => {
              this.ocrDataByPageId[pageId] = { wordsByRect: this.getWordPositionMap(ocrData) };
            }),
          ),
        MAX_CONCURRENT_REQUESTS,
      ),
      scan((sum, _1, _2) => sum + 1, 0),
    );
  }

  private getWordPositionMap(ocrData: OcrData) {
    const rectWordPairs = ocrData.lines.flatMap((line) => {
      return line.words.map((wordEntry) => {
        const p = wordEntry.position;
        const wordRect: WordRect = {
          x: [p.left, p.left + p.width],
          y: [p.top, p.top + p.height],
        };
        return [wordRect, wordEntry.text] as const;
      });
    });

    return new Map<WordRect, string>(rectWordPairs);
  }

  getWord(page: CtPage, rect: WordRect): string {
    return this.ocrDataByPageId[page.id].wordsByRect.get(rect) as string;
  }

  getWordsInRect(page: CtPage, rect: WordRect, availableWords?: WordRect[]) {
    const words = availableWords ?? [...this.ocrDataByPageId[page.id].wordsByRect.keys()];
    return words.filter((wordRect) => this.areRectsIntersecting(wordRect, rect));
  }

  areRectsIntersecting(r1: WordRect, r2: WordRect): boolean {
    // https://www.jeffreythompson.org/collision-detection/rect-rect.php
    return r1.x[1] > r2.x[0] && r1.x[0] < r2.x[1] && r1.y[1] > r2.y[0] && r1.y[0] < r2.y[1];
  }

  /**
   * See https://math.stackexchange.com/a/2449249
   */
  getIntersectionArea(rect1: WordRect, rect2: WordRect): number {
    const axisIntersections = this.getAxisIntersectionLengths(rect1, rect2);
    return axisIntersections[0] * axisIntersections[1];
  }

  /**
   * Returns a Vector [x, y] where x resp. y is the length of the intersection of the given
   * rects on the x resp. y-axis.
   * Note: This values make sense only if the rects are actually intersecting.
   */
  getAxisIntersectionLengths(rect1: WordRect, rect2: WordRect): Vector2 {
    return [
      Math.max(rect1.x[0], rect2.x[0]) - Math.min(rect1.x[1], rect2.x[1]),
      Math.max(rect1.y[0], rect2.y[0]) - Math.min(rect1.y[1], rect2.y[1]),
    ];
  }

  getArea(rect: WordRect) {
    return (rect.x[1] - rect.x[0]) * (rect.y[1] - rect.y[0]);
  }

  getWordRects(page: CtPage): WordRect[] {
    return [...this.ocrDataByPageId[page.id].wordsByRect.keys()];
  }
}
