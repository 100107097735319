import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, from, map, mergeMap, Observable, tap } from 'rxjs';
import { PageApiClient } from './page-api-client.service';

export const MAX_CONCURRENT_REQUESTS = 10;

@Pipe({
  name: 'loadImage',
  standalone: true,
})
export class LoadImagePipe implements PipeTransform {
  constructor(private pageImageService: PageImageService) {}

  transform(pageId: string): Observable<string> {
    return this.pageImageService.getImageSrc(pageId);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PageImageService {
  public imageSubjectById = new Map<string, BehaviorSubject<string>>();

  private readonly defaultImage = 'assets/images/ecn-logo.svg';

  constructor(private pageApiClient: PageApiClient) {}

  public init(pageIds: string[]) {
    from(pageIds)
      .pipe(
        tap((id) => this.prepareImageSubject(id)),
        mergeMap((id) => this.loadImageBlob(id), MAX_CONCURRENT_REQUESTS),
      )
      .subscribe();
  }

  private prepareImageSubject(id: string) {
    const subject = new BehaviorSubject(this.defaultImage);
    this.imageSubjectById.set(id, subject);
  }

  private loadImageBlob(id: string) {
    return this.pageApiClient
      .getImageForPage(id)
      .pipe(
        map((imageBlob) => this.imageSubjectById.get(id)!.next(URL.createObjectURL(imageBlob))),
      );
  }

  public getImageSrc(pageId: string): Observable<string> {
    return this.getImageSubject(pageId);
  }

  private getImageSubject(pageId: string): BehaviorSubject<string> {
    if (!this.imageSubjectById.has(pageId)) {
      throw new Error(`image src requested for id '${pageId}' which does not exist in subject map`);
    }

    return this.imageSubjectById.get(pageId)!;
  }

  public isImageLoaded(pageId: string): boolean {
    return this.getImageSubject(pageId).value !== this.defaultImage;
  }
}
