import { inject, Injectable } from '@angular/core';
import { CtBatch, CtDocument } from '../../models/ct-batch-model';
import { PersistDataService } from './persist-data.service';

@Injectable({
  providedIn: 'root',
})
export class CtBatchStoreService {
  private persistDataService = inject(PersistDataService);

  private _ctBatch: CtBatch = {} as CtBatch;
  private _batchPageCount = 0;

  private _ctBatchId: string | null = this.persistDataService.ctBatchId;

  set ctBatch(ctBatch: CtBatch) {
    this._ctBatch = ctBatch;
    this._batchPageCount = ctBatch.documents.reduce(
      (sum, document) => sum + document.pages.length,
      0,
    );
  }

  get ctBatch(): CtBatch {
    return this._ctBatch;
  }

  get ctBatchPageCount(): number {
    return this._batchPageCount;
  }

  set ctBatchId(ctBatchId: string) {
    this._ctBatchId = ctBatchId;
    this.persistDataService.ctBatchId = ctBatchId;
  }

  get ctBatchId(): string | null {
    return this._ctBatchId;
  }

  get forderungsaufstellungen(): CtDocument[] {
    return this.ctBatch.documents.filter((document) => {
      return document.documentClass === 'FORDERUNGSAUFSTELLUNG';
    });
  }
}
