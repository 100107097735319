import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Confirmation, ConfirmationService, SharedModule } from 'primeng/api';
import { CtBatchStoreService } from '../../services/app-state/ct-batch-store.service';
import { DocumentPreviewComponent } from '../../domains/preview/document-preview.component';
import { DataCapturingTableViewComponent } from '../../domains/data-capturing/data-capturing-table-view/data-capturing-table-view.component';
import { WorkflowService } from '../../services/app-state/workflow.service';
import { DiscardTableDialogComponent } from '../../domains/dialogs/discard-table-dialog/discard-table-dialog.component';
import { TableOverlayStoreService } from '../../services/overlay/table-overlay-store.service';
import { ExtractedDataStoreService } from '../../services/extracted-data/extracted-data-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CtDocument } from '../../models/ct-batch-model';

@UntilDestroy()
@Component({
  selector: 'app-table-extraction-page',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    SharedModule,
    DocumentPreviewComponent,
    DataCapturingTableViewComponent,
    DiscardTableDialogComponent,
  ],
  templateUrl: './table-extraction-page.component.html',
})
export class TableExtractionPageComponent implements OnInit {
  private ctBatchStoreService = inject(CtBatchStoreService);
  protected workflowService = inject(WorkflowService);
  private confirmationService = inject(ConfirmationService);
  private tableOverlayStoreService = inject(TableOverlayStoreService);
  private extractedDataStoreService = inject(ExtractedDataStoreService);

  currentDocument!: CtDocument;

  @ViewChild('formElementContainer') formElementContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('discardTableDialog') discardTableDialog!: Confirmation;

  ngOnInit() {
    this.workflowService.currentForderungsaufstellung$
      .pipe(untilDestroyed(this))
      .subscribe((index) => {
        const forderungsaufstellungen = this.ctBatchStoreService.forderungsaufstellungen;

        if (forderungsaufstellungen.length > 0) {
          this.currentDocument = forderungsaufstellungen[index!];
        }
      });
  }

  discardAndGoToPrevView() {
    this.tableOverlayStoreService.deleteUnconfirmedTables();
    this.workflowService.navigateToPrevView();
  }
}
